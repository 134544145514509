import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import './index.css';

type Dimensions = { screenWidth: number, screenHeight: number }

const calculateDimensions: () => Dimensions = () => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  return { screenWidth, screenHeight };
};

export const useResizeListener = (
  listener: (dimensions: Dimensions) => void
) => {
  const callback = useCallback(() => {
    const dimensions = calculateDimensions();
    listener(dimensions);
  }, [listener]);

  // provide dimensions before first render and call listener initially
  useLayoutEffect(callback, [callback]);

  useEffect(() => {
    window.addEventListener("resize", callback);

    return () => window.removeEventListener("resize", callback);
  }, [callback]);
};

export default function Layout() {
  const [dimensions, setDimensions] = useState(calculateDimensions)
  const style = { "--100vh": `${dimensions.screenHeight}px` } as React.CSSProperties;

  useResizeListener(setDimensions);

  return (
    <div className="App" style={style}>
      <Outlet />
    </div>
  );
}