import cx from 'classnames';
import { LinkProps as RouterLinkProps, Link as RouterLink } from "react-router-dom";
import '../Button/index.css';
import './index.css';
import { ButtonProps } from '../Button';

export type LinkProps = {
  variant?: ButtonProps['variant'] | 'text' | 'link';
};

export default function Link({ className, variant = 'text', ...props }: LinkProps & RouterLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  return (
    <RouterLink className={cx('Link', ['primary', 'secondary', 'tertiary'].includes(variant) && 'Button', variant, className)} {...props} />
  );
}