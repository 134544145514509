import cx from 'classnames';
import './index.css';

export type InputProps = {

};

export default function Input({ className, ...props }: InputProps & React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <input className={cx('Input', className)} {...props} />
  );
}