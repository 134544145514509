import './index.css';

export default function Glitch({ children }: React.PropsWithChildren) {
  return (
    <span className="Glitch">
      <span className="Filled1">{children}</span>
      <span className="Glitchoutline">{children}</span>
      <span className="Filled2">{children}</span>
    </span>
  );
}
