import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutlet,
  useParams,
} from "react-router-dom";
import "yet-another-react-lightbox/styles.css";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import arrow from "../../img/Arrow.svg";
import mask from "../../img/Logo Mask.svg";
import check from "../../img/Check.svg";
import "./index.css";
import Link from "../Link";
import { MerchData } from "./utils";
import Space from "../Space";
import Menu from "../Menu";
import { request } from "../../utils";
import Button from "../Button";
import Select from "../Select";

function MerchCard({ id, name, slug, price, picture }: MerchData) {
  const content = (
    <>
      <img
        src={picture}
        className="MerchCardCover"
        alt={`${name} Product Cover`}
      />
      <Space size="s" />
      <div className="MerchCardContent">
        <div className="MerchCardDetails">
          <span className="MerchCardName">{name.replace("-", "\u2011")}</span>
          <Space size="xs" />
          <span className="MerchCardPrice">{price}</span>
        </div>
        <Button variant="primary">Details</Button>
      </div>
    </>
  );

  return (
    <Link variant="none" to={`/merch/${slug}-${id}`} className="MerchCard">
      {content}
    </Link>
  );
}

export function MerchListScreen() {
  const [products, setProducts] = useState<MerchData[] | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const result = await request("/products");
        setProducts(result.products);
      } catch (e) {
        setProducts([]);
      }
    })();
  }, []);

  return (
    <>
      <Menu>
        <h1 className="MerchListTitle">Merch</h1>
      </Menu>

      <div className="MerchListScreen">
        {!products && (
          <>
            <Space size="xl" />
            <span className="MerchListInfo">Loading products...</span>
          </>
        )}
        {products && products.length === 0 && (
          <>
            <Space size="xl" />
            <span className="MerchListInfo">Currently no products.</span>
          </>
        )}
        {products &&
          products.map((product) => (
            <Fragment key={product.id}>
              <Space size="xl" />
              <MerchCard {...product} />
            </Fragment>
          ))}
      </div>
    </>
  );
}

export function MerchDetailScreen() {
  const params = useParams();
  const id = params.product?.split("-").slice(-1)[0] || "";
  const [product, setProduct] = useState<MerchData | null>(null);
  const [size, setSize] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean | undefined>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;

    (async () => {
      try {
        const result = await request(`/products/${encodeURIComponent(id)}`);
        setProduct(result.product);
      } catch (e) {
        setProduct(null);
      }
    })();
  }, [id]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!product) return;

      const cart = JSON.parse(localStorage.getItem("cart") || "[]");
      cart.push({
        id: product.id,
        variation: parseInt(size, 10),
        quantity: parseInt(quantity, 10),
      });
      localStorage.setItem("cart", JSON.stringify(cart));
      setSubmitted(true);
      navigate("/cart");
    },
    [product, quantity, size, navigate]
  );

  return (
    <>
      <Menu>
        <Link variant="none" to="/merch" className="MerchBackButton">
          <img src={arrow} className="MerchArrow" alt="Arrow Back" />
        </Link>
      </Menu>

      <Space size="l" />

      {!product && <span className="MerchListInfo">Loading product...</span>}
      {product && (
        <form className="MerchDetailScreen" onSubmit={handleSubmit}>
          <h2 className="MerchTitle">
            {product.name.toUpperCase().replace("-", "\u2011")}
          </h2>
          <Space size="l" />
          <img
            className="MerchCover"
            src={product.picture}
            alt={`${product.name}`}
          />
          <Space size="l" />
          <p className="MerchDescription">{product.description}</p>
          <Space size="xxl" />

          <span className="MerchAddPrice">{product.price}</span>
          <Space size="s" />
          <div className="MerchOptions">
            <Select
              className="MerchOption"
              emptyText="Size"
              options={product.variations.map((variation) => ({
                value: `${variation.id}`,
                name: variation.name,
              }))}
              required
              value={size}
              onChange={(event) => setSize(event.target.value)}
            />
            <Select
              className="MerchOption"
              emptyText="Quantity"
              options={Array.from({ length: 10 }).map((_, index) => ({
                value: `${index + 1}`,
                name: `${index + 1}`,
              }))}
              required
              value={quantity}
              onChange={(event) => setQuantity(event.target.value)}
            />
          </div>

          <Space size="s" />

          <Button
            variant={submitted ? "tertiary" : "primary"}
            disabled={submitted !== false || !product}
            type="submit"
            className="MerchAddButton"
          >
            {submitted === undefined ? (
              "Adding..."
            ) : submitted ? (
              <>
                Added{" "}
                <img src={check} className="LoginCheck" alt="Check Icon" />
              </>
            ) : (
              "Add to cart"
            )}
          </Button>
          <Space size="xxl" />
        </form>
      )}
    </>
  );
}

export default function MerchPage() {
  const location = useLocation();
  const nodeRef = useRef(null);
  const currentOutlef = useOutlet();

  return (
    <div className="MerchPage" data-pathname={location.pathname}>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={400}
          classNames="MerchContent"
          unmountOnExit
        >
          {() => (
            <main ref={nodeRef} className="MerchContent">
              {currentOutlef}
            </main>
          )}
        </CSSTransition>
      </SwitchTransition>

      <footer className="EventsFooter">
        <Link to="/live" variant="none">
          <img src={mask} className="EventsLogo" alt="Stockholm Noir Mask" />
        </Link>
      </footer>
    </div>
  );
}
