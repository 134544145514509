import cx from 'classnames';
import '../Button/index.css';
import './index.css';

export type SpaceProps = {
  size: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
};

export default function Space({ className, size, ...props }: SpaceProps & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cx('Space', size, className)} {...props} />
  );
}