import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../auth";
import { useEffect } from "react";

export default function InvitePage() {
  const params = useParams();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.code?.toLowerCase() === '3b21f6') {
      auth.signin(params.code, () => {
        navigate('/live/inferno-symphony/?code=lucifer');
      })
      return;
    } else if (params.code?.toLowerCase() === 'd10b96') {
      auth.signin(params.code, () => {
        navigate('/merch');
      })
      return;
    }

    navigate('/');
  }, [auth, navigate, params.code]);

  return null;
}
