import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutlet,
  useSearchParams,
} from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import mask from "../../img/Logo Mask.svg";
import arrow from "../../img/Arrow.svg";
import spotify from "../../img/Spotify.svg";
import instagram from "../../img/Insta.svg";
import check from "../../img/Check.svg";
import "./index.css";
import Link from "../Link";
import Input from "../Input";
import Button from "../Button";
import Space from "../Space";
import { request } from "../../utils";
import Checkbox from "../Checkbox";
import { useAuth } from "../../auth";

export function SplashScreen() {
  return (
    <div className="SplashScreen">
      <Link variant="primary" to="/login" className="LoginButton">
        Login
      </Link>
      <Link variant="primary" to="/register" className="RegisterButton">
        Register
      </Link>
    </div>
  );
}

export function LoginScreen() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [submitted, setSubmitted] = useState<boolean | undefined>(false);

  useEffect(() => {
    const params = {
      email: searchParams.get("email"),
      code: searchParams.get("code"),
    };

    (async () => {
      try {
        if (params.email && params.code) {
          const response = await request("/verify", {
            method: "POST",
            body: JSON.stringify(params),
          });

          if (response.verified) {
            auth.signin(params.email, () => {
              navigate("/live");
            });
          } else {
            navigate("/");
          }
        } else if (params.email) {
          (document.getElementById("email") as HTMLInputElement).value =
            params.email;
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [auth, navigate, searchParams]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    (async () => {
      try {
        setSubmitted(undefined);
        const formData = new FormData(event.currentTarget);
        const body = JSON.stringify(Object.fromEntries(formData));
        const result = await request("/login", { method: "POST", body });

        if (result.url) {
          navigate(result.url);
        } else if (result.success) {
          setSubmitted(true);
        } else {
          setSubmitted(false);
        }
      } catch (error) {
        setSubmitted(false);
      }
    })();
  };

  return (
    <div className="LoginScreen">
      <Link variant="primary" to="/login" className="LoginButton">
        Login
      </Link>
      <Link variant="primary" to="/register" className="RegisterButton">
        Register
      </Link>
      <Link variant="none" to="/" className="LoginBackButton">
        <img src={arrow} className="LoginArrow" alt="Arrow Icon" />
      </Link>
      <Space size="xl" />
      <form className="LoginForm" onSubmit={handleSubmit}>
        <Input
          placeholder="enter your email"
          name="email"
          id="email"
          type="email"
          autoComplete="email"
          required
        />
        <Space size="xl" />
        <Button
          variant={submitted ? "tertiary" : "primary"}
          disabled={submitted !== false}
          type="submit"
        >
          {submitted === undefined ? (
            "Sending..."
          ) : submitted ? (
            <>
              Link sent{" "}
              <img src={check} className="LoginCheck" alt="Check Icon" />
            </>
          ) : (
            "Get link"
          )}
        </Button>
      </form>
    </div>
  );
}

export function RegisterScreen() {
  const [searchParams] = useSearchParams();
  const [submitted, setSubmitted] = useState<boolean | undefined>(false);
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const email = searchParams.get("email");
    if (email) {
      (document.getElementById("email") as HTMLInputElement).value = email;
    }
  }, [searchParams]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    (async () => {
      try {
        setSubmitted(undefined);
        const formData = new FormData(event.currentTarget);
        const body = JSON.stringify(Object.fromEntries(formData));
        const result = await request("/register", { method: "POST", body });

        if (result.url) {
          navigate(result.url);
        } else if (result.success) {
          setSubmitted(true);
        } else {
          setSubmitted(false);
          setError(result.error);
          setTimeout(setError, 3000, "");
        }
      } catch (error) {
        setSubmitted(false);
      }
    })();
  };

  const handlePhone = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (!event.currentTarget.value) {
        event.currentTarget.value = "+46";
        event.currentTarget.selectionStart = event.currentTarget.selectionEnd =
          event.currentTarget.value.length;
      }
    },
    []
  );

  return (
    <div className="RegisterScreen">
      <Link variant="primary" to="/login" className="LoginButton">
        Login
      </Link>
      <Link variant="primary" to="/register" className="RegisterButton">
        Register
      </Link>
      <Link variant="none" to="/" className="LoginBackButton">
        <img src={arrow} className="LoginArrow" alt="Arrow Icon" />
      </Link>
      <Space size="m" />
      <form className="RegisterForm" onSubmit={handleSubmit}>
        <Input
          placeholder="first name"
          name="fname"
          id="fname"
          autoComplete="given-name"
          required
        />
        <Space size="s" />
        <Input
          placeholder="last name"
          name="lname"
          id="lname"
          autoComplete="family-name"
          required
        />
        <Space size="s" />
        <Input
          placeholder="email"
          name="email"
          id="email"
          type="email"
          autoComplete="email"
          required
        />
        <Space size="s" />
        <Input
          placeholder="phone number"
          name="phone"
          id="phone"
          type="tel"
          autoComplete="tel"
          required
          pattern="\+\d+"
          onFocus={handlePhone}
        />
        <Space size="m" />
        <Checkbox name="terms" required>
          I agree to the{" "}
          <Link
            to="https://stockholmnoir.com/StockholmNoir_TermsAndConditions.pdf"
            target="_blank"
          >
            Terms and Conditions
          </Link>
        </Checkbox>
        <Space size="xl" />
        <Button
          variant={submitted || error ? "tertiary" : "primary"}
          disabled={submitted !== false || !!error}
          type="submit"
        >
          {error ? (
            error
          ) : submitted === undefined ? (
            "Sending..."
          ) : submitted ? (
            <>
              Mail sent{" "}
              <img src={check} className="LoginCheck" alt="Check Icon" />
            </>
          ) : (
            "Register"
          )}
        </Button>
      </form>
    </div>
  );
}

export default function LoginPage() {
  const location = useLocation();
  const nodeRef = useRef(null);
  const currentOutlef = useOutlet();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.user && auth.user.indexOf("@") !== -1) {
      navigate("/live");
    } else {
      auth.signout(() => {});
    }
  }, [auth, navigate]);

  return (
    <div className="LoginPage" data-pathname={location.pathname}>
      <header className="LoginHeader">
        <Link to="/" variant="none">
          <img src={mask} className="LoginLogo" alt="Stockholm Noir Mask" />
        </Link>
      </header>

      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={400}
          classNames="LoginContent"
          unmountOnExit
        >
          {() => <main ref={nodeRef}>{currentOutlef}</main>}
        </CSSTransition>
      </SwitchTransition>

      <footer className="LoginFooter">
        <Link
          to="https://open.spotify.com/artist/2ACCqah94vUZNJOKqDb1yG"
          variant="none"
          target="_blank"
        >
          <img src={spotify} className="LoginSocials" alt="Spotify Icon" />
        </Link>
        <Link
          to="https://www.instagram.com/stockholmnoirmusic/"
          variant="none"
          target="_blank"
        >
          <img src={instagram} className="LoginSocials" alt="Instagram Icon" />
        </Link>
      </footer>
    </div>
  );
}
