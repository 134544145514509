import cx from "classnames";
import "./index.css";

export type SelectProps = {
  emptyText: string;
  options: { value: string; name: string }[];
};

export default function Select({
  className,
  emptyText,
  options,
  ...props
}: SelectProps & React.SelectHTMLAttributes<HTMLSelectElement>) {
  return (
    <select className={cx("Select", className)} {...props}>
      <option value="">{emptyText}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>{option.name}</option>
      ))}
    </select>
  );
}
