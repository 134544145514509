import cx from 'classnames';
import './index.css';

export type ButtonProps = {
  variant: 'primary' | 'secondary' | 'tertiary' | 'small' | 'none';
};

export default function Button({ className, variant, ...props }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button className={cx('Button', variant, className)} {...props} />
  );
}