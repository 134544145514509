import React from "react";
import { Navigate } from "react-router-dom";

const STORAGE_KEY = 'user';

type AuthContextType = {
  user: any;
  signin: (user: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
};

const localAuthProvider = {
  signin(user: string, callback: VoidFunction) {
    localStorage.setItem(STORAGE_KEY, user);
    setTimeout(callback, 100); // fake async
  },
  signout(callback: VoidFunction) {
    localStorage.removeItem(STORAGE_KEY);
    setTimeout(callback, 100);
  },
};

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<any>(localStorage.getItem(STORAGE_KEY));

  const signin = (newUser: string, callback: VoidFunction) => {
    return localAuthProvider.signin(newUser, () => {
      setUser(newUser);
      callback();
    });
  };

  const signout = (callback: VoidFunction) => {
    return localAuthProvider.signout(() => {
      setUser(null);
      callback();
    });
  };

  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();

  if (!auth.user) {
    return <Navigate to="/" />;
  }

  return children;
}