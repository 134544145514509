import { useParams } from "react-router-dom";
import cafeOperaCover from "../../img/CafeOperaCover.jpg";
import infernoCover from "../../img/InfernoCover.jpg";
import videoCover from "../../img/VideoCover.jpg";
import video2Cover from "../../img/Video2Cover.png";
import { useMemo } from "react";

export type EventData = {
  id: string;
  name: string;
  location: string;
  date: string;
  cover: string;
  disabled: boolean;
  description: string;
  video?: {
    cover: string;
    url: string;
    ratio: number;
    code?: string;
  };
  gallery: {
    src: string;
    width: number;
    height: number;
  }[];
};

export const events: EventData[] = [
  {
    id: "cafe-opera",
    name: "Café Opera",
    location: "Stockholm",
    date: "19.06.2024",
    cover: cafeOperaCover,
    disabled: false,
    description:
      "On Wednesday, June 19th 2024 the most exclusive Noppe Bar Summer Party invited Stockholm Noir for an audiovisual performance including the epic cantata Carmina Burana (1936), accompanied by a live quire at Café Opera in Stockholm, Sweden.",
    video: {
      cover: video2Cover,
      url: "https://dpcdsj5v51eea.cloudfront.net/public/CafeOpera/Aftermovie.mp4",
      ratio: 16 / 9,
    },
    gallery: [],
  },
  {
    id: "inferno-symphony",
    name: "Inferno Symphony",
    location: "Stockholm",
    date: "01.09.2023",
    cover: infernoCover,
    disabled: false,
    description:
      "On Friday, September 1st 2023 the Inferno Symphony album was celebrated with a release party and private concert for 500 exclusive guests which took place in Eric Ericsonhallen in Stockholm, Sweden.",
    video: {
      cover: videoCover,
      url: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Aftermovie.mp4",
      ratio: 16 / 9,
    },
    gallery: [
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery1.jpg",
        width: 4931,
        height: 3287,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery2.jpg",
        width: 4837,
        height: 3225,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery3.jpg",
        width: 4954,
        height: 3302,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery4.jpg",
        width: 4820,
        height: 3214,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery5.jpg",
        width: 4266,
        height: 2844,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery6.jpg",
        width: 4906,
        height: 3271,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery7.jpg",
        width: 4265,
        height: 2838,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery8.jpg",
        width: 4785,
        height: 3190,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery9.jpg",
        width: 4824,
        height: 3216,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery10.jpg",
        width: 2957,
        height: 1972,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery11.jpg",
        width: 4834,
        height: 3222,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery12.jpg",
        width: 4933,
        height: 3289,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery13.jpg",
        width: 5002,
        height: 3335,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery14.jpg",
        width: 5040,
        height: 3360,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery15.jpg",
        width: 4970,
        height: 3313,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery16.jpg",
        width: 4359,
        height: 2906,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery21.jpg",
        width: 2500,
        height: 1666,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery22.jpg",
        width: 2500,
        height: 1666,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery23.jpg",
        width: 2500,
        height: 1666,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery24.jpg",
        width: 2500,
        height: 1666,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery25.jpg",
        width: 2500,
        height: 1666,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery26.jpg",
        width: 1666,
        height: 2500,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery27.jpg",
        width: 1666,
        height: 2500,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery28.jpg",
        width: 2500,
        height: 1666,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery29.jpg",
        width: 2500,
        height: 1666,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery30.jpg",
        width: 2500,
        height: 1666,
      },
      {
        src: "https://dpcdsj5v51eea.cloudfront.net/public/InfernoSymphony/Gallery31.jpg",
        width: 2500,
        height: 1666,
      },
    ],
  },
];

export const useEvent = () => {
  const params = useParams();
  const event = useMemo(
    () => events.find((ev) => ev.id === params.event),
    [params.event]
  );
  return event;
};
