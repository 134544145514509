const endpoint =
  process.env.NODE_ENV === "development"
    ? `http://${window.location.hostname}:8080`
    : "https://stockholmnoir.com/backend";

export const request = async (url: string, options: RequestInit = {}) => {
  const response = await fetch(`${endpoint}${url}`, {
    ...options,
    headers: {
      ...options.headers,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};
