import { useEffect, useRef, useState } from "react";
import "./index.css";
import Link from "../Link";
import mask from "../../img/Logo Mask.svg";
import Glitch from "../Glitch";

const targetDate = new Date("2024-11-08T22:30:00Z");

const getTime = () => {
  const date = new Date();
  return [date.getHours(), date.getMinutes(), date.getSeconds()] as const;
};

export function CountdownScreen() {
  const [hands, setHands] = useState(getTime);
  const handsRef = useRef(hands);
  const [height] = useState(window.innerHeight);
  const style = { "--100vh": `${height}px` } as React.CSSProperties;
  const delta = Math.max(
    0,
    Math.ceil((targetDate.getTime() - Date.now()) / 1000)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const time = getTime();
      const currentHands = handsRef.current;
      if (
        currentHands &&
        (time[0] !== currentHands[0] ||
          time[1] !== currentHands[1] ||
          time[2] !== currentHands[2])
      ) {
        setHands(time);
        handsRef.current = time;
      }
    }, 100);

    return () => {
      clearTimeout(interval);
    };
  }, [setHands, handsRef]);

  return (
    <div className="Countdown" style={style}>
      <div className="CountdownTargets">
        <div className="CountdownTarget">
          <img src={mask} className="LoginLogo" alt="Mask Logo" />
        </div>
      </div>

      <div className="CountdownFilter1">
        <div className="CountdownFilter2">
          <div className="CountdownFilter3">
            <div className="CountdownBackground1" />
            <div className="CountdownBackground2" />
            <div className="CountdownBackground3" />
          </div>
        </div>
      </div>

      <div className="CountdownNumbers">
        <div className="CountdownSegment">
          <div className="CountdownValue">
            <Glitch>
              {Math.floor(delta / (60 * 60 * 24))
                .toString()
                .padStart(2, "0")}
            </Glitch>
          </div>
        </div>
        <div className="CountdownSegment">
          <div className="CountdownValue">
            <Glitch>
              {Math.floor((delta / (60 * 60)) % 24)
                .toString()
                .padStart(2, "0")}
            </Glitch>
          </div>
        </div>
        <div className="CountdownSegment">
          <div className="CountdownValue">
            <Glitch>
              {Math.floor((delta / 60) % 60)
                .toString()
                .padStart(2, "0")}
            </Glitch>
          </div>
        </div>
        <div className="CountdownSegment">
          <div className="CountdownValue">
            <Glitch>
              {Math.floor(delta % 60)
                .toString()
                .padStart(2, "0")}
            </Glitch>
          </div>
        </div>
      </div>

      <div className="CountdownFooter">
        <div className="CountdownButtons">
          <Link variant="primary" to="/login" className="CountdownLogin">
            Login
          </Link>
          <Link variant="primary" to="/register" className="CountdownRegister">
            Register
          </Link>
        </div>
      </div>
    </div>
  );
}
