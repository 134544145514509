import cx from 'classnames';
import './index.css';

export type CheckboxProps = {
};

export default function Checkbox({ children, className, ...props }: CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <label className="Checkbox">
      <input type="checkbox" className={cx('CheckboxBox', className)} {...props} />
      <span className="CheckboxLabel">{children}</span>
    </label>
  );
}