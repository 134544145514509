import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "../Layout";
import LoginPage, { LoginScreen, RegisterScreen } from "../LoginPage";
import EventsPage, {
  EventsListScreen,
  EventDetailScreen,
  EventGalleryScreen,
} from "../EventsPage";
import MerchPage, { MerchListScreen, MerchDetailScreen } from "../MerchPage";
import { AuthProvider, RequireAuth } from "../../auth";
import InvitePage from "../InvitePage";
import { CountdownScreen } from "../Countdown";
import CartPage, { CartListScreen } from "../CartPage";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route index element={<CountdownScreen />} />

        <Route element={<Layout />}>
          <Route path="/" element={<LoginPage />}>
            <Route path="login" element={<LoginScreen />} />
            <Route path="register" element={<RegisterScreen />} />
          </Route>

          <Route
            path="/live"
            element={
              <RequireAuth>
                <EventsPage />
              </RequireAuth>
            }
          >
            <Route index element={<EventsListScreen />} />
            <Route path=":event" element={<EventDetailScreen />} />
            <Route path=":event/gallery" element={<EventGalleryScreen />} />
          </Route>

          <Route
            path="/merch"
            element={
              <RequireAuth>
                <MerchPage />
              </RequireAuth>
            }
          >
            <Route index element={<MerchListScreen />} />
            <Route path=":product" element={<MerchDetailScreen />} />
          </Route>

          <Route
            path="/cart"
            element={
              <RequireAuth>
                <CartPage />
              </RequireAuth>
            }
          >
            <Route index element={<CartListScreen />} />
          </Route>

          <Route path="/invite/:code?" element={<InvitePage />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
